/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Select,
  Slide,
  TextField,
  Grid,
  Button,
  Checkbox,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCustomerAddress,
  updateCustomerPersonalInfo,
} from "../../redux/actions/customers.action";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const UpdateCustomer = ({ open, _onClose, customer, location, address }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const _customer = useSelector((state) => state.customer);

  const [details, setDetails] = useState({
    phone: "",
    name: "",
    email: "",
    prepay: false,
  });

  const [info, setInfo] = useState({
    address: "",
    display: true,
  });

  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (open) {
      if (address) {
        if ("display" in address) {
          setInfo(address);
        } else {
          setInfo({ ...address, display: true });
        }
      }

      if (customer.details) {
        if ("prepay" in details) {
          setDetails(customer.details);
        } else {
          setDetails({ ...customer.details, prepay: false });
        }
      }
    }
  }, [open]);

  useEffect(() => {
    if (_customer?.updated) {
      setUpdating(false);
      if (!_customer.message) {
        _onClose();
      }
    }
  }, [_customer]);

  function _onDetailsChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    setDetails((prevState) => {
      return { ...prevState, [name]: value };
    });
  }

  function _onInfoChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    setInfo((prevState) => {
      return { ...prevState, [name]: value };
    });
  }

  async function _onSubmit(event) {
    event.preventDefault();
    setUpdating(true);
    if (location) {
      dispatch(updateCustomerAddress({ ...info, phone: details.phone }));
    } else {
      dispatch(updateCustomerPersonalInfo(details));
    }
  }

  return (
    <Dialog
      open={open}
      className={classes.root}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        classes: {
          root: classes.paper,
        },
      }}
    >
      <DialogTitle className={classes.header}>
        <div align="right" style={{ marginBottom: -40 }}>
          <IconButton
            onClick={() => {
              _onClose();
              setDetails({ name: "", email: "", role: "" });
            }}
            className={classes.closeBtn}
          >
            <Close />
          </IconButton>
        </div>
        {location ? "Update Address" : "Update Customer"}
      </DialogTitle>

      <DialogContent className={classes.content}>
        <div style={{ display: "inline-flex" }}>
          <div
            className={info.display && "display"}
            style={{ display: "inline-flex" }}
          >
            <p style={{ fontWeight: "500" }}>Display</p>
            <Checkbox
              checked={info.display}
              onChange={() => {
                setInfo((prevState) => {
                  return {
                    ...prevState,
                    display: !info.display,
                  };
                });
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
          <div className="prepay" style={{ display: "inline-flex" }}>
            <p style={{ fontWeight: "500" }}>Prepay</p>
            <Checkbox
              checked={details.prepay}
              onChange={() => {
                setDetails((prevState) => {
                  return {
                    ...prevState,
                    prepay: !details.prepay,
                  };
                });
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        </div>

        <h3>Personal Information</h3>
        <form>
          <TextField
            fullWidth
            value={details.phone}
            disabled
            name="phone"
            onChange={_onDetailsChange}
            variant="outlined"
            label="Phone Number"
            className={classes.input}
          />

          <TextField
            fullWidth
            value={details.name}
            name="name"
            disabled={location && true}
            onChange={_onDetailsChange}
            variant="outlined"
            label="Full name"
            className={classes.input}
          />

          <TextField
            fullWidth
            value={details.email}
            disabled={location && true}
            name="email"
            onChange={_onDetailsChange}
            variant="outlined"
            label="Email"
            className={classes.input}
          />

          <br />
          <br />
          <br />

          {location && (
            <>
              <div style={{ marginBottom: 50 }}>
                <h3>Order Information</h3>
                <TextField
                  fullWidth
                  value={info.alt_phone}
                  name="alt_phone"
                  onChange={_onInfoChange}
                  variant="outlined"
                  label="Alternative Phone Number"
                  className={classes.input}
                />
                {address.type === "delivery" && (
                  <>
                    <TextField
                      fullWidth
                      value={info.address}
                      name="address"
                      onChange={_onInfoChange}
                      variant="outlined"
                      label="Address"
                      className={classes.input}
                    />
                    <TextField
                      fullWidth
                      rows={3}
                      rowsMax={3}
                      value={info.address_instruction}
                      name="address_instruction"
                      onChange={_onInfoChange}
                      variant="outlined"
                      label="Address Instruction"
                      className={classes.input}
                    />
                  </>
                )}

                {address.type === "instore" && (
                  <>
                    <TextField
                      fullWidth
                      disabled
                      value={address.collection_name}
                      name="collection_name"
                      variant="outlined"
                      label="Collection Name"
                      className={classes.input}
                    />
                    <TextField
                      fullWidth
                      disabled
                      value={address.collection_id}
                      name="collection_id"
                      variant="outlined"
                      label="Collector Phone or ID"
                      className={classes.input}
                    />
                  </>
                )}

                {address.type === "kerbside" && (
                  <>
                    <TextField
                      fullWidth
                      disabled
                      value={address.vehicle_registration}
                      name="vehicle_registration"
                      variant="outlined"
                      label="Vehicle Registration"
                      className={classes.input}
                    />
                    <TextField
                      fullWidth
                      disabled
                      value={address.vehicle_make_model}
                      name="vehicle_make_model"
                      variant="outlined"
                      label="Vehicle Make or Model"
                      className={classes.input}
                    />
                    <TextField
                      fullWidth
                      disabled
                      value={address.vehicle_colour}
                      name="vehicle_colour"
                      variant="outlined"
                      label="Vehicle Color"
                      className={classes.input}
                    />
                  </>
                )}

                <div>
                  <TextField
                    disabled
                    value={address.total_orders}
                    name="total_orders"
                    variant="outlined"
                    label="Orders"
                    size="small"
                    className={classes.input}
                  />
                </div>

                <div>
                  <TextField
                    disabled
                    value={
                      address.source === "ao"
                        ? "Agent Ordering"
                        : address.source
                    }
                    name="source"
                    variant="outlined"
                    label="Source"
                    size="small"
                    className={classes.input}
                  />
                </div>
                <div
                  className={info.confirm && "confirm"}
                  style={{ display: "inline-flex" }}
                >
                  <p>Confirmed</p>
                  <Checkbox
                    checked={info.confirm}
                    onChange={() => {
                      setInfo((prevState) => {
                        return {
                          ...prevState,
                          confirm: !info.confirm,
                        };
                      });
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>

                <div
                  className={info.prepay && "prepay"}
                  style={{ display: "inline-flex" }}
                >
                  <p>Prepay</p>
                  <Checkbox
                    checked={info.prepay}
                    onChange={() => {
                      setInfo((prevState) => {
                        return {
                          ...prevState,
                          prepay: !info.prepay,
                        };
                      });
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
              </div>

              {address.type === "delivery" ? (
                <>
                  <h3>Delivery Address Information</h3>

                  <TextField
                    fullWidth
                    value={info.nickname}
                    name="nickname"
                    onChange={_onInfoChange}
                    variant="outlined"
                    label="Nickname"
                    className={classes.input}
                  />
                  <TextField
                    fullWidth
                    value={info.house_number}
                    name="house_number"
                    onChange={_onInfoChange}
                    variant="outlined"
                    label="House Number"
                    className={classes.input}
                  />
                  <TextField
                    fullWidth
                    value={info.street}
                    name="street"
                    onChange={_onInfoChange}
                    variant="outlined"
                    label="Street"
                    className={classes.input}
                  />

                  <TextField
                    fullWidth
                    value={info.city}
                    name="city"
                    onChange={_onInfoChange}
                    variant="outlined"
                    label="City"
                    className={classes.input}
                  />

                  <TextField
                    fullWidth
                    value={info.directions}
                    name="directions"
                    onChange={_onInfoChange}
                    variant="outlined"
                    label="Directions"
                    className={classes.input}
                  />

                  <TextField
                    fullWidth
                    value={address.longitude}
                    name="longitude"
                    disabled
                    variant="outlined"
                    label="Longitude"
                    className={classes.input}
                  />

                  <TextField
                    fullWidth
                    value={address.latitude}
                    disabled
                    name="latitude"
                    variant="outlined"
                    label="Latitude"
                    className={classes.input}
                  />
                </>
              ) : (
                <>
                  <h3>Store Information</h3>
                  <TextField
                    fullWidth
                    value={address.city}
                    name="city"
                    disabled
                    variant="outlined"
                    label="City"
                    className={classes.input}
                  />
                  <TextField
                    fullWidth
                    value={address.complex_name}
                    name="complex_name"
                    disabled
                    variant="outlined"
                    label="Complex Name"
                    className={classes.input}
                  />
                  <TextField
                    fullWidth
                    value={address.longitude}
                    name="longitude"
                    disabled
                    variant="outlined"
                    label="Longitude"
                    className={classes.input}
                  />

                  <TextField
                    fullWidth
                    value={address.latitude}
                    disabled
                    name="latitude"
                    variant="outlined"
                    label="Latitude"
                    className={classes.input}
                  />
                </>
              )}

              <div
                className={info.verified && "verified"}
                style={{ display: "inline-flex", marginBottom: -10 }}
              >
                <p>Verified</p>
                <Checkbox
                  checked={info.verified}
                  onChange={() => {
                    setInfo((prevState) => {
                      return {
                        ...prevState,
                        verified: !info.verified,
                      };
                    });
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>

              <div>
                {address.type === "delivery" && address.verified_date && (
                  <TextField
                    disabled
                    value={address.verified_date}
                    name="verified_date"
                    variant="outlined"
                    label="Verified Date"
                    size="small"
                    style={{
                      width: "20%",
                    }}
                    className={classes.input}
                  />
                )}
              </div>

              <div>
                <FormControl
                  className={classes.select}
                  variant="outlined"
                  margin="dense"
                  style={{
                    marginTop: 10,
                    width: "15%",
                    backgroundColor: "inherit",
                  }}
                >
                  <div value="">Rating</div>
                  <Select
                    native
                    value={info.rating}
                    onChange={(event) => {
                      setInfo((prevState) => {
                        return {
                          ...prevState,
                          rating: event.target.value,
                        };
                      });
                    }}
                    inputProps={{
                      name: "rating",
                      id: "rating",
                    }}
                  >
                    {[1, 2, 3, 4, 5].map((rate, index) => (
                      <option key={index} value={rate}>
                        {rate}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </>
          )}

          <br />
          <br />
          <Grid container justify="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                disabled={updating}
                className={classes.btn}
                color="primary"
                onClick={() => {
                  _onClose();
                  setDetails({ name: "", email: "", role: "" });
                }}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item>
              <Button
                onClick={_onSubmit}
                variant="contained"
                className={classes.btn}
                color="secondary"
              >
                {updating ? (
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: -2,
                    }}
                  >
                    <Loader type="Oval" color="#fff" width="20" height="20" />
                  </div>
                ) : (
                  "Save"
                )}
              </Button>
            </Grid>
          </Grid>
          <br />
        </form>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#c71c24",
    textAlign: "center",
    padding: "8px 12px",
    color: "#fff",

    "& p": {
      margin: 0,
      fontSize: 15,
      textAlign: "left",
    },
  },

  content: {
    borderTop: "3px #fff  solid",
  },

  btn: {
    textTransform: "none",
  },

  closeBtn: {
    color: "#fff",
  },

  label: {
    marginTop: 25,
    marginBottom: 3,
    marginLeft: 3,
    fontSize: 15,
    fontWeight: 600,
  },

  select: {
    backgroundColor: "#fff",

    "& select": {
      minWidth: 20,
    },
  },

  input: {
    backgroundColor: "#fff",
    marginTop: 20,
  },

  check: {
    color: "#099c40",
    "&$checked": {
      color: "#099c40",
    },
  },
}));

export default UpdateCustomer;
