import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import StarRatingComponent from "react-star-rating-component";
import { VerifiedUser } from "@styled-icons/material";
import { useSelector } from "react-redux";

const AddressItem = ({
  title,
  confirm,
  verified,
  prepay,
  source,
  display,
  alt_phone,
  _onEdit,
  rating,
}) => {
  const classes = useStyles();

  const editor = useSelector((state) => state.auth?.user?.editor);

  return (
    <div className={classes.root}>
      {editor && (
        <IconButton
          variant="contained"
          color="secondary"
          className={classes.editBtn}
          onClick={_onEdit}
        >
          <Edit className={classes.btnIcon} />
        </IconButton>
      )}
      <p className={classes.nickname}>
        {title}
        {verified && <VerifiedUser className={classes.verifiedUserIcon} />}
      </p>
      <p className={classes.address}>{display}</p>
      {alt_phone && <p className={classes.alt_phone}>* {alt_phone}</p>}
      <StarRatingComponent
        name="rate2"
        editing={false}
        starCount={5}
        value={Number(rating)}
      />
      <div style={{ marginTop: 5 }}>
        {confirm && (
          <div className={classes.confirm}>
            <p>Confirmed</p>
          </div>
        )}

        {prepay && (
          <div
            className={classes.prepay}
            style={{ marginLeft: confirm ? 5 : 0 }}
          >
            <p>Prepay</p>
          </div>
        )}

        {source && (
          <div
            className={classes.source}
            style={{
              marginLeft: prepay && confirm ? 5 : 10,
              marginTop: prepay && confirm ? 5 : 0,
            }}
          >
            <p style={{ textTransform: "capitalize" }}>
              {source === "ao" ? "Agent Ordering" : source}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  editBtn: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: 5,
    visibility: "hidden",
  },

  root: {
    position: "relative",
    width: "100%",
    padding: 7,
    borderRadius: 3,
    marginRight: 19,
    marginBottom: 20,
    "&:hover": {
      backgroundColor: "#EBFCFF",
    },
    "&:hover > button": {
      visibility: "visible",
    },
  },

  nickname: {
    margin: 0,
    marginBottom: 5,
    fontSize: 13,
    fontWeight: 600,
  },

  address: {
    marginRight: 0,
    marginBottom: 5,
    fontSize: 11,
    color: "#525252",
  },

  confirm: {
    margin: 0,
    fontSize: 11,
    display: "inline-flex",
    backgroundColor: "#b1face",
    borderRadius: 40,
    padding: 4,
    "&>p": {
      color: "green",
      margin: 0,
      marginLeft: 5,
    },
  },

  prepay: {
    margin: 0,
    fontSize: 11,
    display: "inline-flex",
    backgroundColor: "#bfdbff",
    borderRadius: 40,
    padding: 4,
    "&>p": {
      color: "#167BFF",
      margin: 0,
      marginLeft: 5,
    },
  },

  source: {
    margin: 0,
    fontSize: 11,
    display: "inline-flex",
    borderRadius: 40,
    backgroundColor: "#fae4bb",
    padding: 4,
    "&>p": {
      color: "#FFA500",
      margin: 0,
      marginLeft: 5,
    },
  },

  btnIcon: {
    fontSize: 17,
  },

  verifiedUserIcon: {
    marginLeft: 5,
    width: 15,
    height: 15,
    color: "#2162cc",
  },

  alt_phone: {
    fontSize: 11,
    color: "#2162cc",
  },
}));
export default AddressItem;
